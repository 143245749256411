import React, { useEffect } from "react";
import CategoryCard from "./components/category-card";

import useCategories from "./hooks/use-categories";
import LoadingCategoryCard from "./components/loading-category-card";
import { Category } from "../shared/types/categories";
import CategoriesDialog from "./components/categories-dialog";

function CategoriesPage() {
  const {
    parentCategories,
    childCategories,
    subChildCategories,
    loading,
    error,
    addCategory,
    updateCategory,
    deleteCategory,
  } = useCategories();
  const [isActionModalOpen, setIsActionModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] =
    React.useState<Category | null>(null);
  const [actionLevel, setActionLevel] = React.useState(1);

  function openActionModal(level: number, category: Category | null) {
    if (!category) {
      setSelectedCategory(null);
    }
    setActionLevel(level);
    setSelectedCategory(category);
    setIsActionModalOpen(true);
  }
  function openDeleteModal(category: Category) {
    setIsDeleteModalOpen(true);
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (loading) {
    return (
      <div className="flex items-center justify-between">
        <LoadingCategoryCard />
        <LoadingCategoryCard />
        <LoadingCategoryCard />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="flex items-start justify-between">
      <CategoryCard
        categories={parentCategories!}
        level={1}
        openActionModal={openActionModal}
        openDeleteModal={openDeleteModal}
      />
      <CategoryCard
        categories={childCategories!}
        level={2}
        openActionModal={openActionModal}
        openDeleteModal={openDeleteModal}
      />
      <CategoryCard
        categories={subChildCategories!}
        openDeleteModal={openDeleteModal}
        level={3}
        openActionModal={openActionModal}
      />
      <CategoriesDialog
        onClose={() => setSelectedCategory(null)}
        level={actionLevel}
        onCategoryCreated={addCategory}
        onCategoryUpdated={updateCategory}
        open={isActionModalOpen}
        setOpen={setIsActionModalOpen}
        category={selectedCategory}
      />
    </div>
  );
}

export default CategoriesPage;
