import { PaperClipIcon } from "@heroicons/react/20/solid";
import React from "react";
import { MediaFile } from "../../shared/types/section";
import toast from "react-hot-toast";
import { CatalogueItem } from "../../shared/types/catalogues";
import apiService from "../../services/api-service";
import { MinusCircleIcon } from "@heroicons/react/24/outline";

type Props = {
  mediaFile: MediaFile;
  catalogueItem: CatalogueItem;
  onFileDeleted: (file: MediaFile) => void;
};

function FileContainer({ mediaFile, catalogueItem, onFileDeleted }: Props) {
  const baseCataloguesUrl = `catalogues/${catalogueItem.catalogue?.id}/items/${catalogueItem.id}`;

  const deleteFile = async () => {
    onFileDeleted(mediaFile);
    const { error } = await apiService.delete(
      `${baseCataloguesUrl}/files/${mediaFile.id}`
    );
    if (error) {
      toast.error("Error al eliminar el archivo");
      return;
    }
  };

  async function getPresignedUrl() {
    const { data, error } = await apiService.get(
      `${baseCataloguesUrl}/files/${mediaFile.id}/signedUrl`
    );

    if (error) {
      toast.error("Error al obtener el archivo");
      return;
    }

    window.open(data, "_blank");
  }

  return (
    <li className="group relative flex-shrink-0 w-[350px] flex items-center justify-between py-4 pl-4 pr-5 text-xs leading-6 border border-gray-200 rounded-md">
      <button
        className="absolute top-1 right-1 hidden group-hover:block text-red-600 hover:text-red-800"
        onClick={() => deleteFile()}
      >
        <MinusCircleIcon className="h-4 w-4" aria-hidden="true" />
      </button>
      <div className="flex w-0 flex-1 items-center">
        <PaperClipIcon aria-hidden="true" className="h-4 w-4 text-gray-400" />
        <div className="ml-4 flex min-w-0 flex-1 gap-2">
          <span className="truncate font-medium">{mediaFile.fileName}</span>
          <span className="text-gray-400">{mediaFile.size}mb</span>
        </div>
      </div>
      <div className="ml-4">
        <button
          className="font-medium text-blue-600 hover:text-blue-500"
          onClick={() => getPresignedUrl()}
        >
          Descargar
        </button>
      </div>
    </li>
  );
}

export default FileContainer;
