/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import CustomCheckBox from "../../users/components/check-box";
import { Site } from "../../shared/types/sites";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useSitesContext } from "../../context/SitesContext";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  site: Site | null;
  onSiteCreated: (site: Site) => void;
  onSiteUpdated: (site: Site) => void;
};

export default function SitesDrawer({
  isOpen,
  onClose,
  site,
  onSiteCreated,
  onSiteUpdated,
}: Props) {
  const { sites } = useSitesContext();
  const [open, setOpen] = useState(isOpen);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [publicSite, setPublicSite] = useState(true);
  const [visible, setVissible] = useState(false);
  const [path, setPath] = useState("");
  const [nameError, setNameError] = useState("");
  const [loading, setLoading] = useState(false);

  function handleClose() {
    onClose();
    setName("");
    setDescription("");
    setPublicSite(true);
    setVissible(false);
    setPath("");
    setNameError("");
    setLoading(false);
  }

  const editMode = site !== null;

  function checkName() {
    const validNameRegex = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ\s]+$/;

    if (!validNameRegex.test(name)) {
      return "El nombre solo puede contener letras y espacios";
    }

    return "";
  }

  useEffect(() => {
    setOpen(isOpen);
    if (editMode) {
      setName(site!.name);
      setDescription(site!.description || "");
      setPublicSite(site!.public);
      setVissible(site!.visible);
    } else {
      setName("");
      setPublicSite(true);
      setVissible(false);
      setDescription("");
    }
  }, [isOpen, site]);

  useEffect(() => {
    const error = checkName();
    setNameError(error);

    if (name && !error) {
      const path = name.toLowerCase().trim().replace(/ /g, "-");
      setPath(path);
    }
  }, [name]);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    if (editMode) {
      const { data, error } = await apiService.put<Site>(`sites/${site!.id}`, {
        name,
        description,
        public: publicSite,
        path,
        order: site!.order,
        visible,
      });
      if (error) {
        setLoading(false);
        toast.error("Error al actualizar el sitio");
        return;
      }
      onSiteUpdated(data!);
      setLoading(false);
    } else {
      const { data, error } = await apiService.post<Site>("sites", {
        name,
        description,
        public: publicSite,
        visible,
        path,
        order: sites.length + 1,
      });
      if (error) {
        setLoading(false);
        toast.error("Error al crear el sitio");
        return;
      }
      onSiteCreated(data!);
      setLoading(false);
    }
    handleClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <form
                className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                onSubmit={handleSubmit}
              >
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-gray-700 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        Agregar Sitio
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="relative rounded-md bg-gray-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => onClose()}
                        >
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-gray-300">
                        Agrega un nuevo sitio a la plataforma
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                      <div className=" pb-5 pt-6">
                        <div className="my-4">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Nombre
                          </label>
                          <CustomInput
                            placeholder="Nombre"
                            required
                            error={nameError}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="my-4">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Descripcion
                          </label>
                          <CustomInput
                            placeholder="Descripcion"
                            value={description}
                            required
                            onChange={(e) => setDescription(e.target.value)}
                          />
                        </div>

                        {!nameError && path && (
                          <p>
                            Dirección:{" "}
                            <span className=" underline text-blue-500">
                              /{path}
                            </span>
                          </p>
                        )}

                        <div className="mt-8">
                          <legend className="text-sm font-medium leading-6 text-gray-900">
                            Clasificación del sitio
                          </legend>
                          <p className="text-xs font-medium leading-6 text-gray-600">
                            Permiso de vizualisacion de paginas
                          </p>
                        </div>
                        <fieldset>
                          <div className="mt-2 space-y-4">
                            <CustomCheckBox
                              title="Publico"
                              description=""
                              value={publicSite}
                              onChange={() => setPublicSite(true)}
                            />
                            <CustomCheckBox
                              title="Confidencial"
                              description=""
                              value={!publicSite}
                              onChange={() => setPublicSite(false)}
                            />
                          </div>
                        </fieldset>
                        <div className="mt-8">
                          <legend className="text-sm font-medium leading-6 text-gray-900">
                            Tipo de Sitio
                          </legend>
                          <p className="text-xs font-medium leading-6 text-gray-600">
                            Define si un sitio es visible en el menu de
                            navegacion
                          </p>
                        </div>
                        <fieldset>
                          <div className="mt-2 space-y-4">
                            <CustomCheckBox
                              title="Visible"
                              description=""
                              value={visible}
                              onChange={() => setVissible(true)}
                            />
                            <CustomCheckBox
                              title="No visible"
                              description=""
                              value={!visible}
                              onChange={() => setVissible(false)}
                            />
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <CustomButton
                    title="Cancelar"
                    onClick={() => handleClose()}
                    filled={false}
                  />
                  <CustomButton
                    title={editMode ? "Actualizar" : "Agregar"}
                    submit
                    loading={loading}
                    filled
                    className="ml-3"
                  />
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
