import React from "react";

type Props = {
  title: string;
  description: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

function CustomCheckBox({ title, description, value, onChange }: Props) {
  return (
    <div className="mt-2 space-y-4">
      <div className="relative flex items-start">
        <div className="flex h-6 items-center">
          <input
            type="checkbox"
            aria-describedby="comments-description"
            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
            checked={value}
            onChange={(e) => onChange(e.target.checked)}
          />
        </div>
        <div className="ml-3 text-sm leading-6">
          <label htmlFor="comments" className="font-medium text-gray-900">
            {title}
          </label>
          <p id="comments-description" className="text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CustomCheckBox;
