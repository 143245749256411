import React, { useState } from "react";
import FileContainer from "./file-container";
import { getRoleByPermissions } from "../../users/utils/user-utils";
import { useAuthContext } from "../../context/AuthContext";
import { MediaFile, MediaLink } from "../../shared/types/section";
import CustomDropdown from "../../shared/components/general/custom-dropdown";
import MediaFileDialog from "./media-file-dialog";
import { CatalogueItem } from "../../shared/types/catalogues";
import MediaLinkDialog from "../../wiki/components/media-link-dialog";
import MediaLinks from "../../wiki/components/SectionLinks";

type props = {
  catalogueItem: CatalogueItem;
};

function CatalogueMedia({ catalogueItem }: props) {
  const { user } = useAuthContext();
  const canEdit =
    getRoleByPermissions(user?.permission!) === "super-admin" ||
    getRoleByPermissions(user?.permission!) === "documenter";

  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
  const [mediaFiles, setMediaFiles] = useState<MediaFile[]>(
    catalogueItem.files
  );
  const [mediaLinks, setMediaLinks] = useState<MediaLink[]>(
    catalogueItem.links
  );
  const [linkToEdit, setLinkToEdit] = useState<MediaLink | null>(null);

  const handleFileCreated = (newFile: MediaFile) => {
    setMediaFiles([...mediaFiles, newFile]);
  };

  const handleLinkCreated = (newLink: MediaLink) => {
    setMediaLinks([...mediaLinks, newLink]);
  };

  const handleFileDeleted = (file: MediaFile) => {
    setMediaFiles(mediaFiles.filter((f) => f.id !== file.id));
  };

  const handleLinkDeleted = (link: MediaLink) => {
    setMediaLinks(mediaLinks.filter((l) => l.id !== link.id));
  };

  const handleLinkUpdated = (link: MediaLink) => {
    setMediaLinks(mediaLinks.map((l) => (l.id === link.id ? link : l)));
  };

  return (
    <section className="relative mb-10">
      <p className="mt-5 border-b border-b-pantone text-lg pb-1">Multimedia</p>
      {canEdit && (
        <div className="absolute top-1.5 right-0 mt-2">
          <CustomDropdown
            title="Nuevo"
            menuItems={[
              {
                title: "Archivo",
                onClick: () => setIsFileDialogOpen(true),
              },
              {
                title: "Link",
                onClick: () => setIsLinkDialogOpen(true),
              },
            ]}
          />
        </div>
      )}
      <div className="mt-5">
        {mediaFiles.length > 0 && (
          <ul className=" w-[1250px] flex overflow-x-auto space-x-4 ">
            {mediaFiles.map((file, i) => (
              <FileContainer
                key={i}
                mediaFile={file}
                onFileDeleted={handleFileDeleted}
                catalogueItem={catalogueItem}
              />
            ))}
          </ul>
        )}
        <ol className="text-sm 	flex  items-center space-x-10 ">
          <MediaLinks
            catalogueItem={catalogueItem}
            onLinkDelete={handleLinkDeleted}
            mediaLinks={mediaLinks}
            onLinkEditClicked={(link) => {
              setLinkToEdit(link);
              setIsLinkDialogOpen(true);
            }}
          />
        </ol>
      </div>
      {isFileDialogOpen && (
        <MediaFileDialog
          open={isFileDialogOpen}
          setOpen={setIsFileDialogOpen}
          catalogueItem={catalogueItem}
          onFileCreated={handleFileCreated}
          onClose={() => setIsFileDialogOpen(false)}
          onFileUpdated={() => {}}
        />
      )}
      {isLinkDialogOpen && (
        <MediaLinkDialog
          open={isLinkDialogOpen}
          link={linkToEdit}
          setOpen={setIsLinkDialogOpen}
          catalogItem={catalogueItem}
          onLinkCreated={handleLinkCreated}
          onClose={() => setIsLinkDialogOpen(false)}
          onLinkUpdated={handleLinkUpdated}
        />
      )}
    </section>
  );
}

export default CatalogueMedia;
