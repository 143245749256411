import React from "react";
import CustomInput from "../../shared/components/general/custom-input";
import CustomTextarea from "../../shared/components/general/custom-text-area";
import CustomButton from "../../shared/components/general/custom-button";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useBasePageContext } from "../../context/BasePageContext";
import { Site } from "../../shared/types/sites";

type Props = {
  backToIdle: () => void;
  site: Site;
  onClose: () => void;
};

function CreateCatalogForm({ backToIdle, site, onClose }: Props) {
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { onCatalogueCreated } = useBasePageContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const { data, error } = await apiService.post("catalogues", {
      name: title,
      siteId: site?.id,
      briefDescription: description,
      pageName: site?.path,
    });

    if (error) {
      setLoading(false);
      toast.error("Error al crear el catálogo, por favor intente de nuevo");
      return;
    }
    setLoading(false);
    onCatalogueCreated(data);
    backToIdle();
  };

  return (
    <form className="space-y-2 flex flex-col" onSubmit={handleSubmit}>
      <CustomInput
        placeholder="Nombre del catálogo"
        required
        onChange={(e) => setTitle(e.target.value)}
      />
      <CustomTextarea
        placeholder="Inserta una breve descripción del catalogo"
        required
        onChange={(e) => setDescription(e.target.value)}
      />

      <div className="flex space-x-2 self-end ">
        <CustomButton
          title="Cancelar"
          filled={false}
          onClick={async () => {
            onClose();
            backToIdle();
          }}
        />
        <CustomButton title="Crear" submit loading={loading} />
      </div>
    </form>
  );
}

export default CreateCatalogForm;
