import React, { useState } from "react";
import CustomButton from "../../shared/components/general/custom-button";
import { EyeIcon } from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { Catalogue } from "../../shared/types/catalogues";
import CreateCatalogueItemDialog from "./create-catalogue-item-dialog";
import apiService from "../../services/api-service";
import { toast } from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";
import { getRoleByPermissions } from "../../users/utils/user-utils";
import CustomDialog from "../../shared/components/general/custom-dialog";
import Pagination from "../../shared/components/general/Pagination";
import { useBasePageContext } from "../../context/BasePageContext";

type Props = {
  catalogue: Catalogue;
};
const itemsPerPage = 5;

function CatalogueTable({ catalogue }: Props) {
  const { user } = useAuthContext();
  const { onCatalogueDeleted } = useBasePageContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const catalogueItems = catalogue.items;
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const canEdit =
    getRoleByPermissions(user?.permission!) === "super-admin" ||
    getRoleByPermissions(user?.permission!) === "documenter";

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = catalogueItems.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  async function handleItemCreated(name: string, briefDescription: string) {
    const { data, error } = await apiService.post(
      `catalogues/${catalogue.id}/items`,
      {
        name,
        briefDescription,
      }
    );

    if (error) {
      toast.error(error.message);
      return;
    }

    navigate(`/catalogo?id=${data.id}`);
  }

  async function deleteCatalogue() {
    const { error } = await apiService.delete(`catalogues/${catalogue.id}`);

    if (error) {
      toast.error("Error al eliminar el catalogo");
      setOpenDeleteDialog(false);
      return;
    }
    onCatalogueDeleted?.(catalogue.id);
    toast.success("Catalogo eliminado correctamente");
    setOpenDeleteDialog(false);
  }

  return (
    <>
      <div className="mb-20" id={`catalogo-${catalogue.id}`}>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">
              {catalogue.name}
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              {catalogue.briefDescription}
            </p>
          </div>
          {canEdit && (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none space-x-2">
              <CustomButton
                title="Nueva entrada"
                filled={false}
                onClick={() => setIsCreateDialogOpen(true)}
              />
              <CustomButton
                title="Eliminar"
                filled={false}
                className="text-pantone border-pantone"
                onClick={() => setOpenDeleteDialog(true)}
              />
            </div>
          )}
        </div>

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Nombre
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Descripcion Corta
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Clasificación
                    </th>

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Detalles
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {currentItems?.map((item) => (
                    <tr key={item.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {item.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.briefDescription}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        Relacion Aqui
                      </td>

                      <td className="whitespace-nowrap  px-7 py-4 text-sm">
                        <NavLink to={`/catalogo?id=${item.id}`}>
                          <EyeIcon className="h-6 w-6" />
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalItems={catalogueItems?.length || 0}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <CreateCatalogueItemDialog
        open={isCreateDialogOpen}
        setOpen={setIsCreateDialogOpen}
        onItemCreated={handleItemCreated}
      />
      <CustomDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        dialogTitle="¿Seguro?"
      >
        <p>Se borraran todos los datos asociados a este catalogo</p>

        <div className="w-full items-center mt-3">
          <CustomButton
            title="Regresar"
            onClick={() => setOpenDeleteDialog(false)}
            filled={false}
          />
          <CustomButton
            title="Eliminar"
            filled={false}
            onClick={() => {
              deleteCatalogue();
            }}
            className=" text-pantone ml-2 border-pantone"
          />
        </div>
      </CustomDialog>
    </>
  );
}

export default CatalogueTable;
