import React from "react";
import CustomDropdown from "../../shared/components/general/custom-dropdown";
import CustomButton from "../../shared/components/general/custom-button";
import { useAuthContext } from "../../context/AuthContext";
import { getRoleByPermissions } from "../../users/utils/user-utils";

type Props = {
  setFileDialogOpen: (value: boolean) => void;
  setLinkDialogOpen: (value: boolean) => void;
  setOpenDeleteDialog: (value: boolean) => void;
};

function SectionActions({
  setFileDialogOpen,
  setLinkDialogOpen,
  setOpenDeleteDialog,
}: Props) {
  const { user } = useAuthContext();

  const canEdit =
    getRoleByPermissions(user?.permission!) === "documenter" ||
    getRoleByPermissions(user?.permission!) === "super-admin";

  function getMenuItems() {
    const items = [];

    if (canEdit) {
      items.push({
        title: "Archivo",
        onClick: () => setFileDialogOpen(true),
      });
    }

    if (canEdit) {
      items.push({
        title: "Link",
        onClick: () => setLinkDialogOpen(true),
      });
    }
    return items;
  }

  const menuItems = getMenuItems();

  return (
    <>
      {menuItems.length > 0 && (
        <CustomDropdown
          containerClassName="w-fit ml-auto"
          title="Agregar"
          menuItems={menuItems}
        />
      )}
      {canEdit && (
        <CustomButton
          title="Eliminar"
          filled={false}
          className="py-1 text-sm px-5 ml-2 text-pantone border-pantone"
          onClick={() => setOpenDeleteDialog(true)}
        />
      )}
    </>
  );
}

export default SectionActions;
