import React, { useEffect, useRef, useState } from "react";
import { RichTextEditor } from "../../shared/components/general/rich-text";
import { CatalogueItem } from "../../shared/types/catalogues";
import CustomButton from "../../shared/components/general/custom-button";
import toast from "react-hot-toast";
import apiService from "../../services/api-service";

type CatalogueContentProps = {
  catalogueItem: CatalogueItem;
};

function CatalogueContent({ catalogueItem }: CatalogueContentProps) {
  const [mode, setMode] = React.useState("view");
  const [newContent, setNewContent] = useState(catalogueItem.content);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target as Node)
      ) {
        setMode("view");
      }
    }

    if (mode === "edit") {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mode]);

  async function updateContent() {
    setLoading(true);
    const { error } = await apiService.put(
      `catalogues/${catalogueItem.catalogue?.id}/items/${catalogueItem.id}`,
      {
        content: newContent,
      }
    );

    if (error) {
      setLoading(false);
      toast.error("Error al actualizar el contenido");
      return;
    }

    toast.success("Contenido actualizado");
    setMode("view");
    setLoading(false);
  }

  return (
    <section>
      <p className="mt-5 border-b border-b-pantone text-lg pb-1 mb-3">
        Descripcion completa
      </p>

      {mode === "edit" ? (
        <div ref={editorRef}>
          <RichTextEditor
            initialContent={newContent}
            onContentChanged={(content) => setNewContent(content)}
            imagesEnabled={false}
          />
          <div className="flex space-x-2 mt-3 ml-auto justify-end">
            <CustomButton
              title="Regresar"
              className="w-20 self-end"
              filled={false}
              onClick={() => {
                setMode("view");
              }}
            />
            <CustomButton
              title="Guardar"
              className="w-20 self-end"
              loading={loading}
              onClick={() => updateContent()}
            />
          </div>
        </div>
      ) : (
        <div className="ql-snow -mx-3.5" onClick={() => setMode("edit")}>
          <div
            className="ql-editor hover:bg-slate-100 transition-all duration-300 rounded-lg cursor-pointer"
            dangerouslySetInnerHTML={{
              __html:
                newContent || "Haz click aqui para agregar una descripcion",
            }}
          />
        </div>
      )}
    </section>
  );
}

export default CatalogueContent;
