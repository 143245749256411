import React, { useState } from "react";
import SubcategoryIndicator from "./subcategory-indicator";
import { CatalogueItem } from "../../shared/types/catalogues";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useAuthContext } from "../../context/AuthContext";
import { getRoleByPermissions } from "../../users/utils/user-utils";
import CustomButton from "../../shared/components/general/custom-button";
import CustomInput from "../../shared/components/general/custom-input";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";

type CatalogueHeaderProps = {
  catalogueItem: CatalogueItem;
};

function CatalogeHeader({ catalogueItem }: CatalogueHeaderProps) {
  const [editMode, setEditMode] = useState(false);
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const canEdit =
    getRoleByPermissions(user?.permission!) === "super-admin" ||
    getRoleByPermissions(user?.permission!) === "documenter";

  const [formData, setFormData] = useState({
    name: catalogueItem.name,
    briefDescription: catalogueItem.briefDescription,
  });

  const handleSave = async () => {
    setLoading(true);
    const { error } = await apiService.put(
      `catalogues/${catalogueItem.catalogue?.id}/items/${catalogueItem.id}`,
      formData
    );

    if (error) {
      toast.error("Error al actualizar el catálogo");
      setLoading(false);
      return;
    }

    toast.success("Catálogo actualizado exitosamente");
    setEditMode(false);
    setLoading(false);
  };

  return (
    <div className="relative">
      {canEdit && editMode === false && (
        <div
          className="absolute top-0 right-0 cursor-pointer"
          onClick={() => setEditMode(true)}
        >
          <PencilIcon className="w-5 h-5" />
        </div>
      )}

      {editMode ? (
        <form className="flex flex-col mb-2">
          <p className="text-xs">Nombre</p>
          <CustomInput
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="mb-2  w-[200px]"
          />
          <p className="text-xs">Descripcion breve</p>
          <CustomInput
            value={formData.briefDescription}
            onChange={(e) =>
              setFormData({ ...formData, briefDescription: e.target.value })
            }
            className="mb-2 w-[280px]"
          />
        </form>
      ) : (
        <>
          <p className="text-xl font-medium">{catalogueItem.name}</p>
          <p>{catalogueItem.briefDescription}</p>
        </>
      )}

      {/* Subcategory indicators  */}
      <SubcategoryIndicator
        subcategories={["Operacion de negocio", "post-venta", "Contrataciones"]}
      />

      {editMode && canEdit && (
        <div className="flex space-x-2 mt-3 ml-auto justify-end">
          <CustomButton
            title="Regresar"
            className="w-20 self-end"
            filled={false}
            onClick={() => {
              setEditMode(false);
            }}
          />
          <CustomButton
            title="Guardar"
            className="w-20 self-end"
            onClick={handleSave}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
}

export default CatalogeHeader;
