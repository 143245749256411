import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Category } from "../../shared/types/categories";

type Props = {
  categories: Category[];
  level: number;
  openActionModal: (level: number, category: Category | null) => void;
  openDeleteModal: (category: Category) => void;
};

function CategoryCard({
  categories,
  level,
  openActionModal,
  openDeleteModal,
}: Props) {
  function getNameByLevel() {
    switch (level) {
      case 1:
        return "Categorias";
      case 2:
        return "Subcategorias";
      case 3:
        return "Otras";
      default:
        return "Categoria";
    }
  }

  return (
    <div className=" rounded-xl border border-gray-200  w-80">
      <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="text-sm/6 font-medium text-gray-900">
          {getNameByLevel()}
        </div>
        <Menu as="div" className="relative ml-auto">
          <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
            <EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5" />
          </MenuButton>
          <MenuItems
            transition
            className="absolute z-50 right-0 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <MenuItem>
              <button
                onClick={() => openActionModal(level, null)}
                className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
              >
                agregar
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
      </div>
      <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
        {categories?.map((category) => (
          <div className="flex justify-between gap-x-4 py-3">
            <div className="font-medium text-gray-900 overflow-hidden text-ellipsis whitespace-nowrap">
              {category.name}
            </div>
            <PencilIcon
              className="text-blue-500 w-4 h-4 cursor-pointer"
              onClick={() => openActionModal(level, category)}
            />
          </div>
        ))}

        {!categories?.length && <p className="my-2">Lista vacía</p>}
      </dl>
    </div>
  );
}

export default CategoryCard;
