import React, { useEffect, useState } from "react";
import { Section, SectionImage } from "../../shared/types/section";
import CustomButton from "../../shared/components/general/custom-button";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { RichTextEditor } from "../../shared/components/general/rich-text";
import "react-quill/dist/quill.snow.css";
import { useAuthContext } from "../../context/AuthContext";
import CustomInput from "../../shared/components/general/custom-input";
import { twMerge } from "tailwind-merge";
import { getRoleByPermissions } from "../../users/utils/user-utils";

type Props = {
  section: Section;
  setSection: React.Dispatch<React.SetStateAction<Section>>;
};

function SectionContent({ section }: Props) {
  const [onView, setOnView] = useState(section);
  const [editMode, setEditMode] = useState(false);
  const { user } = useAuthContext();
  const [newContent, setNewContent] = useState(onView.content);
  const [title, setTitle] = useState(section.title);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<SectionImage[]>([]);
  const editorRef = React.useRef<HTMLDivElement>(null);

  const canEdit =
    getRoleByPermissions(user?.permission!) === "documenter" ||
    getRoleByPermissions(user?.permission!) === "super-admin";

  useEffect(() => {
    setNewContent(onView.content);
    setTitle(onView.title);
  }, [onView]);

  async function updateSection() {
    setLoading(true);
    const { error } = await apiService.put(`sections/${section.id}`, {
      content: newContent,
      title,
    });

    deleteImages(newContent);

    if (error) {
      setLoading(false);
      toast.error("Error al actualizar la sección");
      return;
    }

    setOnView((prev) => ({ ...prev, content: newContent, title }));

    toast.success("Sección actualizada");
    setEditMode(false);
    setLoading(false);
  }

  async function deleteImages(newContent: string) {
    uploadedImages.forEach((sectionImage) => {
      if (!newContent.includes(sectionImage.bucketRef)) {
        apiService.delete(`sections/${section.id}/images/${sectionImage.id}`);
      }
    });
    setOnView((prev) => ({
      ...prev,
      images: prev.images?.filter((image) =>
        newContent.includes(image.bucketRef)
      ),
    }));

    setUploadedImages([]);
  }

  async function onImageUpload(file: File) {
    const { data, error } = await apiService.uploadFile<SectionImage>(
      `sections/${section.id}/images`,
      file
    );

    if (error) {
      toast.error("Error subiendo la imagen");
      return "";
    }

    setUploadedImages((prevImages) => [...prevImages, data!]);

    return data?.presignedUrl!;
  }

  if (editMode) {
    return (
      <div className="flex flex-col w-full space-y-5">
        <CustomInput
          placeholder="Titulo"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="mt-5"
        />
        <RichTextEditor
          initialContent={newContent}
          onContentChanged={(s) => {
            setNewContent(s);
          }}
          onImageUpload={onImageUpload}
        />
        <div className="flex self-end space-x-2">
          <CustomButton
            title="Regresar"
            className="w-20 self-end"
            filled={false}
            disabled={loading}
            onClick={() => {
              deleteImages(newContent);
              setEditMode(false);
            }}
          />
          <CustomButton
            title="Guardar"
            className="w-20 self-end"
            disabled={loading}
            onClick={() => updateSection()}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="ql-snow -mx-3.5">
      <div
        className={twMerge(
          "ql-editor rounded-lg",
          canEdit && " hover:bg-slate-100 transition-all duration-300"
        )}
        dangerouslySetInnerHTML={{ __html: newContent }}
        onClick={(e) => {
          const selection = window.getSelection();
          if (selection && selection.toString().length > 0) {
            // Text is selected; do not proceed with the click action
            return;
          }
          if (canEdit) {
            setEditMode(true);
          }
        }}
      />
    </div>
  );
}

export default SectionContent;
