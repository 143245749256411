import { Link, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useAuthContext } from "../../../context/AuthContext";
import { useBasePageContext } from "../../../context/BasePageContext";
import {
  ArrowLeftEndOnRectangleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Cog6ToothIcon,
} from "@heroicons/react/20/solid";
import { Dispatch, SetStateAction, useState } from "react";
import { getRoleByPermissions } from "../../../users/utils/user-utils";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

function NavBar({ open, setOpen }: Props) {
  const { sections, catalogues } = useBasePageContext();

  const navigate = useNavigate();
  const { logOut, user } = useAuthContext();
  const [isHovering, setIsHovering] = useState(false);

  function handleLogOut() {
    logOut();
    navigate("/");
  }

  const handleScrollToSection = (event: any, href: string) => {
    event.preventDefault();
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    window.history.pushState(null, "", `#${href}`);
  };

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {/* Sidebar component */}
      <div
        className={twMerge(
          "hidden lg:fixed lg:inset-y-0 border-r border-gray-300 lg:flex lg:w-64 lg:flex-col bg-gray-950 transition-transform duration-300",
          open ? "translate-x-0" : "-translate-x-full",
          "z-0 " // Adjust these as needed
        )}
      >
        <div className="flex grow flex-col gap-y-5 border-r px-6 pb-4">
          <div className="flex h-28 shrink-0 items-center">
            <img
              className="h-28 mt-2 mx-auto"
              src="/images/logo.png"
              alt="Altan redes"
            />
          </div>
          <nav className="flex flex-1 flex-col">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li className=" h-80 overflow-y-auto ">
                <ul className=" space-y-1">
                  {sections?.map((item) => (
                    <li key={item.title}>
                      <a
                        href={`#seccion-${item.id}`}
                        className={twMerge(
                          "text-gray-200 hover:text-white hover:bg-gray-600",
                          " flex gap-x-3 rounded-md px-2   text-sm leading-6 font-semibold py-1"
                        )}
                        onClick={(e) =>
                          handleScrollToSection(e, `seccion-${item.id}`)
                        }
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                  {catalogues?.map((item) => (
                    <li key={item.id}>
                      <a
                        href={`#catalogo-${item.id}`}
                        className={twMerge(
                          "text-gray-200 hover:text-white hover:bg-gray-600",
                          " flex gap-x-3 rounded-md px-2   text-sm leading-6 font-semibold py-1"
                        )}
                        onClick={(e) =>
                          handleScrollToSection(e, `catalogo-${item.id}`)
                        }
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="mt-auto border-t border-gray-400 py-2 flex flex-col gap-y-4">
                {getRoleByPermissions(user?.permission!).includes("admin") && (
                  <Link
                    to="/administracion"
                    className="group -mx-2 flex gap-x-3 rounded-md px-2  text-sm leading-6 text-gray-400"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 "
                      aria-hidden="true"
                    />
                    Administración
                  </Link>
                )}
                <button
                  className="group -mx-2 flex gap-x-3 rounded-md px-2 mt-1 text-sm leading-6 text-gray-400"
                  onClick={handleLogOut}
                >
                  <ArrowLeftEndOnRectangleIcon
                    className="h-6 w-6 shrink-0 text-gray-400 "
                    aria-hidden="true"
                  />
                  Log Out
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <>
        <div
          className="fixed bg-gray-500 w-px h-screen   top-0 left-0 transform transition-transform duration-300"
          style={{
            transform: `translateX(${(open ? 245 : 10) + 10}px)`,
          }}
        />

        {/* Toggle Button */}
        <button
          className={twMerge(
            "fixed z-50 bg-gray-200 w-5 h-5 rounded-full top-10 left-0",
            "hover:bg-gray-300 ring-gray-500 ring-1 focus:ring-2",
            "transform transition-all duration-300 ease-in-out",
            "opacity-0 group-hover:opacity-100",
            open ? "translate-x-[245px]" : "translate-x-[10px]",
            isHovering || !open ? "opacity-100" : "opacity-0"
          )}
          onClick={() => setOpen(!open)}
        >
          {open ? (
            <ChevronLeftIcon className="text-gray-800" />
          ) : (
            <ChevronRightIcon className="text-gray-800" />
          )}
        </button>
      </>
    </div>
  );
}

export default NavBar;
