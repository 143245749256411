import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/24/outline";

export function LoadingCataloguePage() {
  return (
    <div className="py-10">
      {/* title and backbtn  */}
      <div className="w-full flex items-center border-b-pantone border-b-[.5px] pb-5 mb-5">
        <ArrowLeftIcon className="w-5 h-5" />
        <p className="mx-auto text-2xl font-medium">Detalles</p>
        <TrashIcon className="w-5 h-5 text-pantone" />
      </div>

      {/* loading content */}
      <div className="flex flex-col gap-y-6 animate-pulse">
        {/* Header section */}
        <div className="bg-white rounded-lg p-6">
          <div className="h-4 bg-gray-200 rounded-full w-1/4 mb-4"></div>
          <div className="space-y-3">
            <div className="h-3 bg-gray-200 rounded-full w-3/4"></div>
            <div className="h-3 bg-gray-200 rounded-full w-1/2"></div>
          </div>
        </div>

        {/* Content section */}
        <div className="bg-white rounded-lg p-6">
          <div className="h-4 bg-gray-200 rounded-full w-1/3 mb-4"></div>
          <div className="grid grid-cols-2 gap-4">
            <div className="h-3 bg-gray-200 rounded-full w-full"></div>
            <div className="h-3 bg-gray-200 rounded-full w-full"></div>
            <div className="h-3 bg-gray-200 rounded-full w-3/4"></div>
            <div className="h-3 bg-gray-200 rounded-full w-3/4"></div>
          </div>
        </div>

        {/* Relations section */}
        <div className="bg-white rounded-lg p-6">
          <div className="h-4 bg-gray-200 rounded-full w-1/4 mb-4"></div>
          <div className="flex gap-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="w-32 h-32 bg-gray-200 rounded-lg"></div>
            ))}
          </div>
        </div>

        {/* Media section */}
        <div className="bg-white rounded-lg p-6">
          <div className="h-4 bg-gray-200 rounded-full w-1/4 mb-4"></div>
          <div className="grid grid-cols-3 gap-4">
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <div key={i} className="w-full h-40 bg-gray-200 rounded-lg"></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
