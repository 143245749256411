import React from "react";
import CustomDialog from "../../shared/components/general/custom-dialog";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onItemCreated: (name: string, briefDescription: string) => Promise<void>;
};

function CreateCatalogueItemDialog({ open, setOpen, onItemCreated }: Props) {
  const [name, setName] = React.useState("");
  const [briefDescription, setBriefDescription] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setLoading(true);
    await onItemCreated(name, briefDescription);
    setLoading(false);
    handleClose();
  }

  function handleClose() {
    setName("");
    setBriefDescription("");
    setOpen(false);
  }

  return (
    <CustomDialog
      dialogTitle="Nueva Entrada"
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <CustomInput
            placeholder="Nombre"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <CustomInput
            placeholder="Descripción breve"
            value={briefDescription}
            onChange={(e) => setBriefDescription(e.target.value)}
            required
          />
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-2">
          <CustomButton title="Cancelar" filled={false} onClick={handleClose} />
          <CustomButton title="Crear" submit loading={loading} />
        </div>
      </form>
    </CustomDialog>
  );
}

export default CreateCatalogueItemDialog;
