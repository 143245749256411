import { useEffect, useState } from "react";
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import CustomInput from "../../shared/components/general/custom-input";
import CustomCheckBox from "./check-box";
import CustomButton from "../../shared/components/general/custom-button";
import User from "../../shared/types/user";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import {
  generateRandomPassword,
  getRoleByPermissions,
  isValidPhoneNumber,
} from "../utils/user-utils";

type Props = {
  user?: User | null;
  isOpen: boolean;
  onClose: () => void;
  onUserUpdate: (user: User) => void;
  onUserCreate: (user: User) => void;
  onUserDelete: (userId: string) => void;
};

export default function UserDrawer({
  user,
  isOpen,
  onClose,
  onUserCreate,
  onUserDelete,
  onUserUpdate,
}: Props) {
  const [open, setOpen] = useState(isOpen);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState(
    user?.permission ? getRoleByPermissions(user.permission) : "reader"
  );
  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetUserLoading, setResetUserLoading] = useState(false);

  function resetFields() {
    setName("");
    setLastName("");
    setEmail("");
    setRole("");
    setPhone("");
    setPhoneError("");
  }

  function verify() {
    if (!isValidPhoneNumber(phone)) {
      setPhoneError(
        "Numero invalido. Debe contener: (+código país seguido del número)"
      );
      return false;
    }

    setPhoneError("");
    return true;
  }

  async function handleDelete() {
    setDeleteLoading(true);
    const { error } = await apiService.delete(`users/${user?.id}`);

    if (error) {
      setDeleteLoading(false);
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }

    setDeleteLoading(false);
    onUserDelete(user!.id);
    onClosed();
  }

  useEffect(() => {
    if (user) {
      setName(user.name);
      setLastName(user.lastName);
      setEmail(user.email);
      setRole(getRoleByPermissions(user.permission));
      setPhone(user.phoneNumber);
    } else {
      resetFields();
    }
  }, [user]);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function onClosed() {
    setOpen(false);
    onClose();
    resetFields();
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const valid = verify();
    if (!valid) {
      return;
    }

    if (user) {
      handleUpdate();
    } else {
      handleCreate();
    }
  }

  async function handleUpdate() {
    setLoading(true);
    const { error, data } = await apiService.put(`users/${user?.id}`, {
      name,
      lastName,
      phoneNumber: phone,
      role: role,
    });

    if (error) {
      setLoading(false);
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }
    setLoading(false);
    onUserUpdate(data);
    onClosed();
    toast.success("Usuario actualizado correctamente");
  }

  async function handleCreate() {
    setLoading(true);
    const { error, data } = await apiService.post("auth/register", {
      name,
      lastName,
      email,
      password: generateRandomPassword(),
      phoneNumber: phone,
      role: role,
    });

    if (error) {
      setLoading(false);
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }
    setLoading(false);
    onUserCreate(data);
    onClose();
    toast.success("Usuario creado correctamente");
  }

  function UserActions() {
    if (user) {
      return (
        <>
          {user.loginAttempts >= 3 && (
            <CustomButton
              title="Desbloaquear usuario"
              className="mt-3 mb-4 mx-4"
              filled={false}
              onClick={async () => {
                setResetUserLoading(true);
                const { error } = await apiService.put(`users/${user?.id}`, {
                  loginAttempts: 0,
                });

                if (error) {
                  toast.error("Se produjo un error, intentalo de nuevo");
                  setResetUserLoading(false);
                  return;
                }
                setResetUserLoading(false);
                toast.success("Usuario desbloqueado correctamente");
                onClosed();
                onUserUpdate({ ...user, loginAttempts: 0 });
              }}
              loading={resetUserLoading}
            />
          )}
          <CustomButton
            title="Desactivar usuario"
            className="mt-3 mb-4 mx-4 bg-red-700 hover:bg-red-800"
            onClick={handleDelete}
            loading={deleteLoading}
          />
        </>
      );
    }
  }

  return (
    <Dialog open={open} onClose={onClosed} className="relative z-10">
      <div className="fixed inset-0" />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <form
                className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                onSubmit={handleSubmit}
              >
                <div className="h-0 flex-1 overflow-y-auto">
                  <div className="bg-gray-700 px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-base font-semibold leading-6 text-white">
                        {user ? "Editar usuario" : "Agregar usuario"}
                      </DialogTitle>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => onClosed()}
                          className="relative rounded-md bg-gray-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-1">
                      <p className="text-sm text-gray-300">
                        {user
                          ? "Edita la información del usuario, asi como sus permisos"
                          : `El usuario recibirá por correo electronico con sus
                        credenciales de acceso`}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col justify-between">
                    <div className="divide-y divide-gray-200 px-4 sm:px-6">
                      <div className="space-y-6 pb-5 pt-6">
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Nombre
                          </label>
                          <CustomInput
                            placeholder="Nombre"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Apellido
                          </label>
                          <CustomInput
                            placeholder="Apellido"
                            value={lastName}
                            required
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Correo Electronico
                          </label>
                          <CustomInput
                            placeholder="Email"
                            value={email}
                            type="email"
                            required
                            disabled={!!user}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Numero de telefono
                          </label>
                          <CustomInput
                            placeholder="+52"
                            required
                            value={phone}
                            error={phoneError}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>

                        <legend className="text-sm font-medium leading-6 text-gray-900">
                          Tipo de usuario
                        </legend>
                        <fieldset>
                          <div className="mt-2 space-y-4">
                            <CustomCheckBox
                              title="Lector"
                              description="Unicamente puede ver el contenido de secciones"
                              value={role === "reader"}
                              onChange={() => {
                                setRole("reader");
                              }}
                            />
                            <CustomCheckBox
                              title="Documentador"
                              description="Puede editar y crear contenido en las secciones, incluyendo archivos y links"
                              value={role === "documenter"}
                              onChange={() => {
                                setRole("documenter");
                              }}
                            />
                            <CustomCheckBox
                              title="Gestor de cuentas"
                              description="Puede editar y crear usuarios"
                              value={role === "account-admin"}
                              onChange={() => {
                                setRole("account-admin");
                              }}
                            />
                            <CustomCheckBox
                              title="Administrador"
                              description="Administración de usuarios y paginas"
                              value={role === "admin"}
                              onChange={() => {
                                setRole("admin");
                              }}
                            />
                            <CustomCheckBox
                              title="Usuario Maestro"
                              description="Acceso completo al sistema"
                              value={role === "super-admin"}
                              onChange={() => {
                                setRole("super-admin");
                              }}
                            />
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <UserActions />
                  </div>
                </div>
                <div className="flex flex-shrink-0 justify-end px-4 py-4">
                  <CustomButton
                    title="Cancelar"
                    onClick={() => onClosed()}
                    filled={false}
                  />
                  <CustomButton
                    title={user ? "Actualizar" : "Crear"}
                    submit
                    loading={loading}
                    filled
                    className="ml-3"
                  />
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
