import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Router from "./router/router";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import BasePageProvider from "./context/BasePageContext";
import SitesProvider from "./context/SitesContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <SitesProvider>
        <BrowserRouter>
          <Toaster />
          <BasePageProvider>
            <Router />
          </BasePageProvider>
        </BrowserRouter>
      </SitesProvider>
    </AuthProvider>
  </React.StrictMode>
);
