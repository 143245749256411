import { ChevronRightIcon } from "@heroicons/react/20/solid";

type Props = {
  subcategories: string[];
};

function SubcategoryIndicator({ subcategories }: Props) {
  return (
    <div aria-label="Breadcrumb" className="flex">
      <ol className="flex items-center space-x-4">
        {subcategories.map((cat, i) => (
          <li key={cat}>
            <div className="flex items-center">
              <p className="mr-4 text-sm font-medium text-gray-500">{cat}</p>
              {i !== subcategories.length - 1 && (
                <ChevronRightIcon
                  aria-hidden="true"
                  className="h-5 w-5  text-gray-400"
                />
              )}
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default SubcategoryIndicator;
