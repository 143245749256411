import DashboardLayout from "../../shared/components/layout/dashboard-layout";
import { SectionContainer } from "./SectionContainer";
import { useBasePageContext } from "../../context/BasePageContext";
import { LoadingSection } from "./LoadingSection";
import { useAuthContext } from "../../context/AuthContext";
import DraggableList from "./DragAndDrop";
import { useState } from "react";
import CustomButton from "../../shared/components/general/custom-button";
import { getRoleByPermissions } from "../../users/utils/user-utils";
import CatalogueTable from "../../catalogues/components/catalogue-table";
import EmptyBasePage from "./EmptyBasePage";

type Props = {
  page: string;
};

function BasePage({ page }: Props) {
  const { sections, error, loading, catalogues } = useBasePageContext();
  const { user } = useAuthContext();
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const isEmpty = !sections || sections.length === 0;

  const canEdit =
    getRoleByPermissions(user?.permission!) === "documenter" ||
    getRoleByPermissions(user?.permission!) === "super-admin";

  function Content() {
    if (loading) {
      return [1, 2].map((i) => <LoadingSection key={i} />);
    }

    if (error) {
      return <p>Error contacta al administrador</p>;
    }

    return (
      <div className="flex flex-col">
        {canEdit && !loading && !error && sections && sections?.length > 1 && (
          <>
            <CustomButton
              title="Organizar secciones"
              className="mt-5 self-end"
              onClick={() => setIsOrderDialogOpen(true)}
              filled={false}
            />
            <DraggableList
              sections={sections!}
              open={isOrderDialogOpen}
              setIsOpen={setIsOrderDialogOpen}
            />
          </>
        )}

        {sections?.map((section) => (
          <SectionContainer key={section.id} section={section} />
        ))}
        <div className={sections?.length === 0 ? "mt-10" : ""}>
          {catalogues?.map((catalogue) => (
            <CatalogueTable key={catalogue.id} catalogue={catalogue} />
          ))}
        </div>

        {isEmpty && <EmptyBasePage />}
      </div>
    );
  }

  return (
    <DashboardLayout>
      <div className="mb-20 space-y-20">
        <Content />
      </div>
    </DashboardLayout>
  );
}

export default BasePage;
