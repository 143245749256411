import { DocumentIcon } from "@heroicons/react/20/solid";
import { useAuthContext } from "../../context/AuthContext";
import { getRoleByPermissions } from "../../users/utils/user-utils";

export function LoadingSection() {
  return (
    <section className="flex flex-col mt-10">
      <div className="flex w-full">
        <div className="flex-1">
          <div role="status" className="max-w-[800px] animate-pulse">
            <div className="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[760px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[430px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[500px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[500px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[500px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[760px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full  max-w-[500px] mb-2.5"></div>

            <div className="h-2 bg-gray-200 rounded-full  max-w-[360px]"></div>
          </div>
          <div className="flex justify-center space-x-20 mt-5">
            <LoadingFile />
            <LoadingFile />
            <LoadingFile />
          </div>
        </div>
      </div>
    </section>
  );
}

export function LoadingFile() {
  const { user } = useAuthContext();
  const canEdit =
    getRoleByPermissions(user?.permission!) === "super-admin" ||
    getRoleByPermissions(user?.permission!) === "documenter";

  return (
    <div className="rounded-lg bg-[#F2F5F9] px-5 py-4 my-2 flex items-center justify-between max-w-[340px] animate-pulse">
      <div className="flex space-x-5 items-center">
        <div className="hidden lg:grid place-items-center w-10 h-10 flex-shrink-0">
          <div className="rounded-lg border border-blue-200 bg-blue-50 w-10 h-10 flex items-center justify-center">
            <DocumentIcon className="w-5 h-5 text-gray-400" />
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          <div className="h-3 bg-gray-300 rounded w-32"></div>
          <div className="h-3 bg-gray-300 rounded w-24"></div>
        </div>
      </div>
      {canEdit && (
        <div className="flex space-x-4 flex-shrink-0 ml-10">
          <div className="w-5 h-5 bg-gray-300 rounded"></div>
          <div className="w-5 h-5 bg-gray-300 rounded"></div>
        </div>
      )}
    </div>
  );
}
