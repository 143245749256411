import { Route, Routes } from "react-router-dom";
import LoginPage from "../auth/login-page";
import AuthValidator from "./AuthValidator";
import BasePage from "../wiki/components/BasePage";
import ActivateAccount from "../auth/activate-account";
import ForgotPassword from "../auth/forgot-password";
import SearchPage from "../search/pages/search-page";
import UsersPage from "../users/users-page";
import MyAccountPage from "../users/my-account-page";
import AdminPage from "../admin/admin-page";
import { useSitesContext } from "../context/SitesContext";
import NotFoundPage from "../shared/components/general/not-found-page";
import CataloguePage from "../catalogues/catalog-page";
import LoadingPage from "../shared/components/general/loading-page";

interface RouteI {
  path: string;
  element: React.ReactNode;
}

const publicRoutes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/activar-cuenta",
    element: <ActivateAccount />,
  },
  {
    path: "/olvide-clave",
    element: <ForgotPassword />,
  },
];

const privateRoutes = [
  {
    path: "/busqueda",
    element: <SearchPage />,
  },
  {
    path: "/usuarios",
    element: <UsersPage />,
  },
  {
    path: "/mi-cuenta",
    element: <MyAccountPage />,
  },
  {
    path: "/administracion",
    element: <AdminPage />,
  },
  {
    path: "/catalogo",
    element: <CataloguePage />,
  },
];

export default function Router() {
  const { sites } = useSitesContext();

  const wikiRoutes = sites.map((site) => {
    return {
      path: `/${site.path}`,
      element: <BasePage page={site.name} />,
    };
  });

  const allPrivateRoutes = [...privateRoutes, ...wikiRoutes];

  return (
    <Routes>
      {/* Public Routes */}
      {publicRoutes.map((route: RouteI) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}

      {/* Private Routes */}
      {allPrivateRoutes.map((route: RouteI) => (
        <Route key={route.path} element={<AuthValidator href={route.path} />}>
          <Route path={route.path} element={route.element} />
        </Route>
      ))}

      {/* Fallback Route for 404 */}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
