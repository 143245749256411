import { useState, useEffect } from "react";

import toast from "react-hot-toast";
import { Category } from "../../shared/types/categories";
import apiService from "../../services/api-service";

function useCategories() {
  const [categories, setCategories] = useState<Category[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [parentCategories, setParentCategories] = useState<Category[] | null>(
    null
  );
  const [childCategories, setChildCategories] = useState<Category[] | null>(
    null
  );
  const [subChildCategories, setSubChildCategories] = useState<
    Category[] | null
  >(null);

  useEffect(() => {
    async function getCategories() {
      setLoading(true);
      setError(null);
      const { data, error } = await apiService.get("categories");
      if (error) {
        toast.error("Error getting categories");
        setError("Error getting categories");
        setLoading(false);
        return;
      }
      setCategories(data);
      setLoading(false);
    }

    getCategories();
  }, []);

  useEffect(() => {
    if (categories === null) return;
    setParentCategories(categories.filter((category) => category.level === 1));
    setChildCategories(categories.filter((category) => category.level === 2));
    setSubChildCategories(
      categories.filter((category) => category.level === 3)
    );
  }, [categories]);

  const addCategory = (newCategory: Category) => {
    setCategories((prevCategories) => [...prevCategories!, newCategory]);
  };

  const updateCategory = (updatedCategory: Category) => {
    setCategories((prevCategories) =>
      prevCategories!.map((category) =>
        category.id === updatedCategory.id ? updatedCategory : category
      )
    );
  };

  const deleteCategory = (categoryId: number) => {
    setCategories((prevCategories) =>
      prevCategories!.filter((category) => category.id !== categoryId)
    );
  };

  return {
    parentCategories,
    childCategories,
    subChildCategories,
    loading,
    error,
    addCategory,
    updateCategory,
    deleteCategory,
  };
}

export default useCategories;
