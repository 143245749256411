import React, { useEffect } from "react";
import Pagination from "../shared/components/general/Pagination";
import CustomButton from "../shared/components/general/custom-button";
import SitesTable from "./components/sites-table";
import SitesDrawer from "../admin/components/sites-drawer";
import { useSitesContext } from "../context/SitesContext";
import { Site } from "../shared/types/sites";

function SitesPage() {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [siteOnView, setSiteOnView] = React.useState<Site | null>(null);
  const { sites, onSiteCreated, onSiteUpdated } = useSitesContext();
  const [page, setPage] = React.useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sites.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    if (siteOnView) {
      setIsDrawerOpen(true);
    }
  }, [siteOnView]);

  return (
    <>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Gestion de Sitios
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Aquí puedes ver agergar y editar los sitios de la base de datos
              del conocimiento
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <CustomButton
              title="Agregar Sitio"
              onClick={() => setIsDrawerOpen(true)}
            />
          </div>
        </div>

        <>
          <SitesTable
            sites={currentItems}
            onEditButtonClick={(site) => setSiteOnView(site)}
          />
          <div className="-mx-8">
            <Pagination
              currentPage={page}
              totalItems={sites.length}
              itemsPerPage={itemsPerPage}
              onPageChange={(page) => {
                setPage(page);
              }}
            />
          </div>
        </>
        <SitesDrawer
          onSiteCreated={onSiteCreated}
          onSiteUpdated={onSiteUpdated}
          isOpen={isDrawerOpen}
          site={siteOnView}
          onClose={() => {
            setIsDrawerOpen(false);
            setSiteOnView(null);
          }}
        />
      </div>
    </>
  );
}

export default SitesPage;
