import React, { useEffect, useState } from "react";
import DashboardLayout from "../shared/components/layout/dashboard-layout";
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CatalogeHeader from "./components/catalogue-header";
import CatalogueContent from "./components/catalogue-content";
import CatalogueMedia from "./components/catalogue-media";
import { CatalogueItem } from "../shared/types/catalogues";
import apiService from "../services/api-service";
import { LoadingCataloguePage } from "./components/loading-catalogue";

function CataloguePage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const [catalogueItem, setCatalogueItem] = useState<CatalogueItem | null>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function getCatalogueItem() {
      setLoading(true);
      const id = searchParams.get("id");
      if (id) {
        const catalogId = parseInt(id);

        if (isNaN(catalogId)) {
          navigate("/home");
          return;
        }

        const { data, error } = await apiService.get(
          `catalogues/${catalogId}/items/${id}`
        );

        if (error) {
          navigate("/home");
          return;
        }

        setCatalogueItem(data);
        setLoading(false);
        return;
      }

      navigate("/home");
    }
    getCatalogueItem();
  }, [navigate, searchParams]);

  if (loading) {
    return (
      <DashboardLayout showSidebar={false}>
        <LoadingCataloguePage />
      </DashboardLayout>
    );
  }

  const handleBackNavigation = () => {
    if (location.key === "default") {
      navigate("/");
    } else {
      navigate(-1);
    }
  };

  return (
    <DashboardLayout showSidebar={false}>
      <div className="py-10">
        {/* title and backbtn  */}
        <div className="w-full flex items-center border-b-pantone border-b-[.5px] pb-5 mb-5">
          <ArrowLeftIcon
            className="w-5 h-5 cursor-pointer"
            onClick={handleBackNavigation}
          />
          <p className="mx-auto text-2xl font-medium">Detalles</p>
          <div className="flex gap-x-3">
            <TrashIcon className="w-5 h-5 cursor-pointer text-pantone" />
          </div>
        </div>

        {/* content */}
        <div className="flex flex-col gap-y-2">
          <CatalogeHeader catalogueItem={catalogueItem!} />
          <CatalogueContent catalogueItem={catalogueItem!} />
          <CatalogueMedia catalogueItem={catalogueItem!} />
        </div>
      </div>
    </DashboardLayout>
  );
}

export default CataloguePage;
