import React, { useState } from "react";
import { Section, MediaFile, MediaLink } from "../../shared/types/section";
import SectionContent from "./SectionContent";

import CustomButton from "../../shared/components/general/custom-button";
import CustomDialog from "../../shared/components/general/custom-dialog";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useBasePageContext } from "../../context/BasePageContext";
import SectionActions from "./SectionActions";
import MediaFileContainer from "./MediaFileContainer";
import MediaLinks from "./SectionLinks";
import MediaFileDialog from "./SectionFileDialog";
import MediaLinkDialog from "./media-link-dialog";

type Props = {
  section: Section;
};

export function SectionContainer({ section }: Props) {
  const { onSectionDeleted } = useBasePageContext();
  const [sectionOnView, setSectionOnView] = useState(section);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [MediaFileOnView, setMediaFileOnView] = useState<MediaFile | null>(
    null
  );

  const [MediaLinkOnView, setMediaLinkOnView] = useState<MediaLink | null>();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  async function deleteSection() {
    const { error } = await apiService.delete(`sections/${section.id}`);

    if (error) {
      toast.error("Error al eliminar la sección");
      setOpenDeleteDialog(false);
    }
    onSectionDeleted(section.id);
    toast.success("Sección eliminada correctamente");
    setOpenDeleteDialog(false);
  }

  return (
    <section className="flex flex-col my-20" id={`seccion-${section.id}`}>
      <div className="flex w-full flex-col">
        <div className="flex-1">
          <div className="flex w-full border-b border-b-gray-400 pb-1">
            <h3 className="text-2xl font-base ">{sectionOnView.title}</h3>
            <div className="flex ml-auto">
              <SectionActions
                setFileDialogOpen={setFileDialogOpen}
                setLinkDialogOpen={setLinkDialogOpen}
                setOpenDeleteDialog={setOpenDeleteDialog}
              />
            </div>
          </div>
          <SectionContent section={section} setSection={setSectionOnView} />
          {sectionOnView.files?.length > 0 && (
            <p className="text-lg mb-2">Documentos Relacionados:</p>
          )}
          <div className="flex flex-col mt-5">
            <div className="flex w-full overflow-x-auto  items-center justify-start  ">
              {sectionOnView?.files?.map((file) => (
                <MediaFileContainer
                  key={file.id}
                  file={file}
                  section={section}
                  onEditClick={() => {
                    setMediaFileOnView(file);
                    setFileDialogOpen(true);
                  }}
                  onDelete={() => {
                    sectionOnView.files = section.files.filter(
                      (f) => f.id !== file.id
                    );
                    setSectionOnView({
                      ...sectionOnView,
                      files: sectionOnView.files,
                    });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <MediaLinks
          section={sectionOnView}
          mediaLinks={sectionOnView.links}
          onLinkDelete={(link) => {
            setSectionOnView({
              ...sectionOnView,
              links: sectionOnView.links.filter((l) => l.id !== link.id),
            });
          }}
          onLinkEditClicked={(link: MediaLink) => {
            setMediaLinkOnView(link);
            setLinkDialogOpen(true);
          }}
        />
      </div>
      <MediaFileDialog
        open={fileDialogOpen}
        setOpen={setFileDialogOpen}
        section={sectionOnView}
        onClose={() => setMediaFileOnView(null)}
        onFileCreated={(MediaFile) => {
          setSectionOnView({
            ...sectionOnView,
            files: [...(sectionOnView.files ?? []), MediaFile],
          });
        }}
        onFileUpdated={(MediaFile) => {
          setSectionOnView({
            ...sectionOnView,
            files: sectionOnView.files?.map((f) =>
              f.id === MediaFile.id ? MediaFile : f
            ),
          });
        }}
        MediaFileOnView={MediaFileOnView}
      />
      <MediaLinkDialog
        section={section}
        open={linkDialogOpen}
        onLinkUpdated={(link) => {
          setSectionOnView({
            ...sectionOnView,
            links: sectionOnView.links.map((l) =>
              l.id === link.id ? link : l
            ),
          });
        }}
        setOpen={setLinkDialogOpen}
        onLinkCreated={(link) => {
          setSectionOnView({
            ...sectionOnView,
            links: [...(sectionOnView?.links || []), link],
          });
        }}
        onClose={() => setMediaLinkOnView(null)}
        link={MediaLinkOnView}
      />
      <CustomDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        dialogTitle="Alerta"
      >
        <p>
          Al eliminar una sección se borraran los archivos y links asociados con
          la misma ¿Deseas continuar?
        </p>

        <div className="w-full items-center mt-3">
          <CustomButton
            title="Regresar"
            onClick={() => setOpenDeleteDialog(false)}
            className="bg-gray-600 hover:bg-gray-700 text-white"
          />
          <CustomButton
            title="Eliminar"
            onClick={() => {
              deleteSection();
            }}
            className="bg-red-700 hover:bg-red-800 text-white ml-2"
          />
        </div>
      </CustomDialog>
    </section>
  );
}
